import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { ImgProps } from "./Img"

interface SEOProps {
  title?: string
  description?: string
  image?: ImgProps["src"]
  pathName?: string
  article?: boolean
  publishedAt?: string
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  image,
  pathName,
  article = false,
  publishedAt
}) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      defaultTitle,
      titleTemplate,
      defaultDescription,
      siteUrl,
      defaultImage,
      twitterUsername
    }
  } = site

  const meta = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathName || "/"}`
  }

  const structuredData = [{
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "YMRO",
    "alternateName": [
      "YMRO AS"
    ],
    "legalName": "YMRO AS",
    "url": "https://ymro.no",
    "foundingDate": "2019",
    "description": "YMRO bygger attraktive boliger tilpasset ulike typer mennesker. For YMRO sine prosjekter er det lagt fokus på helhetsbildet, og hovedvekt på funksjonelle moderne boliger.",
    "logo": "https://ymro.no/uploads/ymro-logo.png",
    "image": meta.image,
    "sameAs": [
      "https://facebook.com/YMRONO",
      "https://instagram.com/YMRO.no",
      "https://linkedin.com/company/YMRO-AS"
    ],
    "numberOfEmployees": {
      "@type" : "QuantitativeValue",
      "name" : "0-10"
    },
    "telephone": "+47-969-48-620",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Oslo",
      "addressRegion": "Oslo",
      "streetAddress": "Parkveien 37",
      "postalCode": "0258",
      "email": "kontakt@ymro.no",
      "telephone": "+47-969-48-620"
    },
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+47-969-48-620",
      "contactType": "customer service"
    }]
  }]

  return (
    <>
      <Helmet
        title={meta.title}
        titleTemplate={titleTemplate}
      >
        {meta.title && <meta name="twitter:title" content={meta.title} />}
        {meta.title && <meta property="og:title" content={meta.title} />}

        {meta.description && <meta name="description" content={meta.description} />}
        {meta.description && <meta property="og:description" content={meta.description} />}
        {meta.description && <meta name="twitter:description" content={meta.description} />}

        {meta.image && <meta name="image" content={meta.image} />}
        {meta.image && <meta property="og:image" content={meta.image} />}
        {meta.image && <meta name="twitter:image" content={meta.image} />}

        {meta.url && <meta property="og:url" content={meta.url} />}

        {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
        {twitterUsername && <meta name="twitter:card" content="summary_large_image" />}

        <meta property="og:type" content="website" />

        {structuredData.map((data, index) => (
          <script
            key={index}
            type="application/ld+json"
          >
            {JSON.stringify(data)}
          </script>
        ))}
      </Helmet>
    </>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
