import React, { FC } from "react"
import marked from "marked"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Img from "../components/Img"
import Link from "../components/Link"
import { PageProps } from "../types"
import Layout from "../components/Layout"
import { ProjectProps } from "../types"

interface ProjectsPageTemplateProps {
  title: string,
  projects: {
    nodes: ProjectProps[]
  }
}

const ProjectsPageTemplate: FC<ProjectsPageTemplateProps> = ({
  title,
  projects
}) => {
  return (
    <main
      id="prosjekter-page"
      className="page"
    >
      <section
        id="intro"
        className="page-section py-24 lg:py-16"
      >
        <div className="page-section-text-wrapper max-w-xl my-0">
          <h1
            className="h2 markdown"
            dangerouslySetInnerHTML={{
              __html: marked(title)
            }}
          />
        </div>

        <div className="text-center mt-8">
          {projects.nodes.map(({
            frontmatter: { title },
            fields: { slug }
          }, index) => (
            <>
              <Link
                key={index}
                to={slug}
              >
                {title}
              </Link>

              {index !== projects.nodes.length - 1 && (
                <span className="mx-2">-</span>
              )}
            </>
          ))}
        </div>
      </section>

      {projects.nodes.map(({
        excerpt,
        frontmatter: { title, meta: { image } },
        fields: { slug }
      }, index) => (
        <section
          id={slug}
          key={slug}
          className="page-section-skew-bg page-section pt-0 lg:pb-16"
        >
          <div
            className="max-w-8xl mx-auto w-full px-4 flex items-center lg:px-0 md:flex-col"
            style={{
              flexDirection: index % 2 === 0 ? "row" : "row-reverse"
            }}
          >
            <div className="flex-2 lg:flex-1 md:w-full md:mb-6">
              <Img
                src={image}
                alt={title}
              />
            </div>

            <div className="flex-1 flex items-center">
              <div
                className="mx-auto w-full max-w-lg p-12 relative lg:py-0 lg:px-6 lg:mx-0 sm:px-0"
                style={{
                  backdropFilter: "blur(18px)",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  marginLeft: index % 2 === 0 ? "-6rem" : "",
                  marginRight: index % 2 !== 0 ? "-6rem" : ""
                }}
              >
                <h2 className="h2">
                  {title}
                </h2>

                <div
                  className="markdown mb-4 mt-6"
                  dangerouslySetInnerHTML={{
                    __html: marked(excerpt)
                  }}
                />

                <Link to={slug}>
                  Se mer &rarr;
                </Link>
              </div>
            </div>
          </div>
        </section>
      ))}
    </main>
  )
}

interface ProjectsPageProps {
  data: {
    markdownRemark: {
      frontmatter: ProjectsPageTemplateProps & PageProps
    },
    projects: {
      nodes: ProjectProps[]
    }
  }
}

const ProjectsPage: React.FC<ProjectsPageProps> = ({ data }) => {
  const { markdownRemark, projects } = data
  const { frontmatter } = markdownRemark
  return (
    <Layout>
      <SEO
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
        image={frontmatter.meta.image}
      />

      <ProjectsPageTemplate
        title={frontmatter.title}
        projects={projects}
      />
    </Layout>
  )
}

export default ProjectsPage

export const ProjectsPageQuery = graphql`
  query ProjectsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
          image {
            childImageSharp {
              fluid(quality: 100 maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
      }
    }

    projects: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            eq: "project"
          },
          showOnProjects: {
            eq: true
          }
        }
      }
    ) {
      nodes {
        excerpt(pruneLength: 280)
        frontmatter {
          title
          meta {
            image {
              childImageSharp {
                fluid(maxWidth: 1920 quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }

  }
`
